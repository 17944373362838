.session-timer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Arial', sans-serif;
    background-color: #ffffff;
    padding: 10px 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px; /* Más largo */
    height: 40px; /* Menos alto */
    margin: 10px auto;
}

.timer-display {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-right: 15px;
}

.pause-button, .play-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.pause-button {
    color: #ff6b6b; /* Color rojo para el botón de pausa */
}

.pause-button:hover {
    background-color: #ffe3e3; /* Fondo rojo claro al pasar el mouse */
}

.play-button {
    color: #4caf50; /* Color verde para el botón de play */
}

.play-button:hover {
    background-color: #e8f5e9; /* Fondo verde claro al pasar el mouse */
}