/* Reports.css */

/* Estilos generales */
.page-content {
    padding: 20px;
  }
  
  /* Contenedor de tarjetas para que todas tengan la misma altura */
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  /* Cada columna debe tener el mismo alto */
  .col-md-4 {
    display: flex;
  }
  
  /* Tarjetas con altura uniforme */
  .report-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 300px; /* Ajusta según necesites */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    background: #ffffff;
  }
  
  /* Efecto hover */
  .report-card:hover {
    transform: translateY(-5px);
  }
  
  /* Estilo del título dentro de las tarjetas */
  .report-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-bottom: 15px;
  }
  
  /* Lista de reportes */
  .report-list {
    flex-grow: 1; /* Asegura que la lista crezca dentro de la tarjeta */
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
  }
  
  /* Elemento de la lista */
  .report-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    color: #555;
  }
  
  /* Último elemento sin borde */
  .report-item:last-child {
    border-bottom: none;
  }
  
  /* Enlace de "Ver" */
  .report-link {
    color: #007bff;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
  }
  
  .report-link:hover {
    color: #0056b3;
    text-decoration: underline;
  }


.chart-container {
  margin: 20px 0;
  text-align: center;
}

/* Estilos para el gráfico de barras */
.recharts-bar {
  fill: #8884d8;
}

.recharts-bar-rectangles {
  fill: #8884d8;
}

.recharts-label {
  font-size: 12px;
  fill: #333;
}

/* Estilos para el gráfico de torta */
.recharts-pie {
  margin: 0 auto;
}

.recharts-pie-label {
  font-size: 12px;
  fill: #333;
}

/* Estilos para la leyenda */
.recharts-legend-wrapper {
  margin-top: 10px;
}

.recharts-legend-item {
  font-size: 14px;
  color: #333;
}

/* Grid para los gráficos secundarios */
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.chart-item {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
}

/* Estilos para el mensaje de advertencia */
.warning-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

/* Estilos para el selector de agentes */
.react-select__control {
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.react-select__menu {
  z-index: 1000;
}

/* Estilos para el datepicker */
.flatpickr-input {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 6px 12px;
  width: 100%;
}