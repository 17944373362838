/* Typifications.css */
.typifications-container {
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
}

.typifications-column {
  flex: 1;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.typifications-column:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.typifications-column h2 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: 600;
}

.typifications-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.typifications-card {
  background-color: #f0f4f8;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.typifications-card:hover {
  background-color: #e2e8f0;
  transform: scale(1.02);
}

.typifications-card.selected {
  background-color: #c6f6d5;
}

.typifications-card.selected:hover {
  background-color: #9ae6b4;
}

.typifications-connector {
  margin-bottom: 10px;
}

.typifications-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 14px;
  font-weight: 500;
}

.typifications-button:hover {
  background-color: #3182ce;
  transform: translateY(-2px);
}

.typifications-button.add-campania {
  background-color: #28a745; /* Verde para agregar campañas */
}

.typifications-button.add-campania:hover {
  background-color: #218838;
}

.typifications-button.add-descripcion {
  background-color: #dc3545; /* Rojo para agregar descripciones */
}

.typifications-button.add-descripcion:hover {
  background-color: #c82333;
}

.typifications-button-icon {
  font-size: 16px;
}
.descripciones-preview {
  margin-top: 10px;
  padding: 5px;
  background-color: #f9f9f9;
  border-radius: 5px;
}
.descripcion-item {
  padding: 3px 0;
}