/* Estilos generales */
.module-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Dos columnas de igual ancho */
  gap: 10px; /* Espacio entre las tarjetas */
}
@media (max-width: 768px) {
  .module-grid {
    grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
  }
}
.module-list {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .header-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .save-button {
    background-color: #2196f3;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .save-button:hover {
    background-color: #1976d2;
  }
  
  .module-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 14px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .module-header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .module-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    margin-right: 12px;
  }
  
  .switch-input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .switch-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 24px;
    transition: 0.4s;
    cursor: pointer;
  }
  
  .switch-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
  
  .switch-input:checked + .switch-slider {
    background-color: #2196f3;
  }
  
  .switch-input:checked + .switch-slider:before {
    transform: translateX(16px);
  }
  
  .module-name {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .submodule-list {
    margin-top: 12px;
    margin-left: 24px;
  }
  
  .submodule-label {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #555;
    margin: 8px 0;
    cursor: pointer;
  }
  
  .submodule-checkbox {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  
  .submodule-name {
    margin-left: 8px;
  }